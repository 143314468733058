import React from 'react'
import styled, { keyframes, css } from 'styled-components'

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`

const Container = styled.div`
  border: 2px solid transparent;
  border-top: 2px solid #ffffff;
  border-radius: 50%;
  width: 1em;
  height: 1em;
  animation: ${spin} 500ms linear infinite;

  ${props =>
    props.dark &&
    css`
      border-top: 2px solid black;
    `}
`

const Loader = props => <Container {...props} />

export default Loader
