import React from 'react'

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="25"
      fill="none"
      viewBox="0 0 100 25"
      {...props}
    >
      <path
        fill={props.fill || '#fff'}
        fillRule="evenodd"
        d="M12.5 0C5.6 0 0 5.6 0 12.5S5.6 25 12.5 25 25 19.4 25 12.5 19.4 0 12.5 0zm0 22.5c-5.525 0-10-4.475-10-10s4.475-10 10-10 10 4.475 10 10-4.475 10-10 10zm6.25-10a6.25 6.25 0 11-12.5 0 6.25 6.25 0 0112.5 0z"
        clipRule="evenodd"
      ></path>
      <path
        fill={props.fill || '#fff'}
        d="M33.75 19.793h2.954v-4.731h2.484c3.213 0 5.083-2.003 5.083-4.917 0-2.9-1.836-4.945-5.008-4.945H33.75v14.593zm2.954-7.204V7.722h1.992c1.706 0 2.532.97 2.532 2.423 0 1.446-.826 2.444-2.518 2.444h-2.006zM56.536 5.2v9.213c0 1.668-1.12 2.872-2.839 2.872-1.712 0-2.838-1.204-2.838-2.872V5.2h-2.954v9.477c0 3.192 2.306 5.323 5.792 5.323 3.473 0 5.793-2.13 5.793-5.323V5.2h-2.954zM63.636 19.793h7.254l1.69-2.544h-5.99V5.2h-2.954v14.594zM83.832 9.397h2.832C86.623 6.796 84.576 5 81.464 5c-3.063 0-5.307 1.767-5.293 4.418-.007 2.152 1.446 3.385 3.806 3.976l1.522.4c1.522.384 2.368.84 2.374 1.824-.007 1.068-.975 1.795-2.476 1.795-1.535 0-2.64-.74-2.736-2.202h-2.859C75.877 18.368 78.04 20 81.431 20c3.411 0 5.417-1.703 5.424-4.375-.007-2.43-1.76-3.72-4.19-4.29l-1.255-.314c-1.214-.292-2.23-.762-2.21-1.81 0-.94.798-1.631 2.244-1.631 1.413 0 2.28.67 2.388 1.817zM90.557 19.793H100V17.25h-6.488v-3.484h4.738V11.22h-4.738V7.743h4.738L99.973 5.2h-9.416v14.593z"
      ></path>
    </svg>
  )
}

export default Icon
