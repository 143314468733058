import React from 'react'
import styled from 'styled-components'

const Div = styled.div``

const Box = ({ as, children, className, style, id, ...rest }) => {
  return (
    <Div
      style={{ ...style, ...rest }}
      className={className}
      as={as}
      {...rest}
      id={id}
    >
      {children}
    </Div>
  )
}

export default Box
